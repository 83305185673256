<template>
  <form @submit.prevent="saveItem">
    <b-card>
      <b-row>
        <b-col cols="12" md="6">
          <div class="form-group">
            <label for="redirect">Redirect URL:</label>
            <input
              type="text"
              class="form-control"
              v-model="formPayload.other.redirect_url"
            />
          </div>
          <div class="form-group">
            <label for="about">About:</label>
            <textarea
              class="form-control"
              v-model="formPayload.other.about"
              style="height: 200px"
              maxlength="500"
            />
            <small style="font-size: 12px"><i>Max: 500</i></small>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="from-group">
            <label for="file" class="mb-0">Image:</label>
            <b-form-file
              id="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              ref="fileInput"
              accept="image/png, image/jpeg, image/jpg"
              @change="handleFileImage($event)"
            />
            <div v-if="previewImage" class="mt-1">
              <img :src="previewImage" class="w-100 product__image rounded" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12" md="6">
        <b-card title="Benefits">
          <b-row class="mt-2">
            <b-col
              cols="12"
              v-for="(benefit, index) in formPayload.other.benefits"
              :key="index"
            >
              <div class="border-bottom mb-2">
                <div class="form-group">
                  <label :for="'text' + index + 1">
                    Text {{ index + 1 }}:
                  </label>
                  <input
                    :id="'text' + index + 1"
                    type="text"
                    class="form-control"
                    v-model="benefit.text"
                    maxlength="50"
                  />
                  <small
                    v-for="(validation, index) in validations[
                      'other.' + 'benefits.' + index + '.text'
                    ]"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
                <div class="form-group">
                  <label :for="'imageBenefits' + index + 1" class="mb-0"
                    >Icons {{ index + 1 }}:</label
                  >
                  <b-form-file
                    :id="'imageBenefits' + index + 1"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    ref="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="changePhoto($event, 'benefits', index)"
                  />
                  <div
                    v-if="formPayload.other.benefits[index].icon != null"
                    class="mt-1"
                  >
                    <input type="hidden" :value="testingBenefit" />
                    <img
                      :src="formPayload.other.benefits[index].preview"
                      class="product__icon rounded"
                    />
                  </div>
                  <div class="d-flex justify-content-end mt-1">
                    <b-button
                      variant="danger"
                      class="btn waves-effect waves-float waves-light btn-danger"
                      style="padding: 6px"
                      @click="removeItem('benefits', index)"
                      v-show="index > 0"
                    >
                      <trash-icon size="1x" class="custom-class"></trash-icon>
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary w-100"
            @click="addForm('benefits')"
          >
            Add Benefits
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card title="Procedures">
          <b-row class="mt-2">
            <b-col
              cols="12"
              v-for="(procedure, index) in formPayload.other.procedures"
              :key="index"
            >
              <div class="border-bottom mb-2">
                <div class="form-group">
                  <label :for="'title' + index + 1">
                    Title {{ index + 1 }}:
                  </label>
                  <input
                    :id="'title' + index + 1"
                    type="text"
                    class="form-control"
                    v-model="procedure.title"
                    maxlength="50"
                  />
                  <small
                    v-for="(validation, index) in validations[
                      'other.' + 'procedures.' + index + '.title'
                    ]"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
                <div class="form-group">
                  <label :for="'text' + index + 1">
                    Text {{ index + 1 }}:
                  </label>
                  <textarea
                    :id="'text' + index + 1"
                    v-model="procedure.text"
                    class="form-control"
                    maxlength="500"
                  ></textarea>
                  <small
                    v-for="(validation, index) in validations[
                      'other.' + 'procedures.' + index + '.text'
                    ]"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
                <div class="form-group">
                  <label :for="'imageProcedures' + index + 1" class="mb-0"
                    >Icons {{ index + 1 }}:</label
                  >
                  <b-form-file
                    :id="'imageProcedures' + index + 1"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    ref="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="changePhoto($event, 'procedures', index)"
                  />
                  <div
                    v-if="formPayload.other.procedures[index].icon != null"
                    class="mt-1"
                  >
                    <input type="hidden" :value="testingProcedure" />
                    <img
                      :src="formPayload.other.procedures[index].preview"
                      class="product__icon rounded"
                    />
                  </div>
                  <div class="d-flex justify-content-end mt-1">
                    <b-button
                      variant="danger"
                      class="btn waves-effect waves-float waves-light btn-danger"
                      style="padding: 6px"
                      @click="removeItem('procedures', index)"
                      v-show="index > 0"
                    >
                      <trash-icon size="1x" class="custom-class"></trash-icon>
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary w-100"
            @click="addForm('procedures')"
          >
            Add Procedures
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import { TrashIcon } from "vue-feather-icons";
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
    BFormFile,
    TrashIcon,
  },

  data() {
    return {
      formPayload: {
        type: "intern-sis",
        other: {
          image: null,
          about: "",
          redirect_url: "",
          benefits: [],
          procedures: [],
        },
      },
      testingBenefit: null,
      testingProcedure: null,
      previewImage: null,
      isLoading: false,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get("/api/v1/admin/page/intern-sis").then((response) => {
        this.formPayload = response.data.data;
        if (this.formPayload.other.image !== null) {
          this.previewImage = this.formPayload.other.image;
        }
        console.log(this.formPayload);
        for (
          let index = 0;
          index < this.formPayload.other.benefits.length;
          index++
        ) {
          const element = this.formPayload.other.benefits[index];
          this.formPayload.other.benefits[index].preview = element.icon;
          // this.formPayload.other.benefits[index].icon = element.icon;
        }
        for (
          let index = 0;
          index < this.formPayload.other.procedures.length;
          index++
        ) {
          const element = this.formPayload.other.procedures[index];
          this.formPayload.other.procedures[index].preview = element.icon;
          // this.formPayload.other.procedures[index].icon = element.icon;
        }
        this.isLoading = false;
      });
    },
    addForm(name) {
      if (name === "benefits") {
        this.formPayload.other.benefits.push({
          text: "",
          icon: null,
        });
      } else {
        this.formPayload.other.procedures.push({
          text: "",
          title: "",
          icon: null,
        });
      }
    },
    removeItem(name, index) {
      if (name === "benefits") {
        this.formPayload.other.benefits.splice(index, 1);
      } else {
        this.formPayload.other.procedures.splice(index, 1);
      }
    },
    handleFileImage(event) {
      const file = event.target.files[0];
      this.formPayload.other.image = file;
      this.showImage(event.target.files[0]);
    },
    showImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    changePhoto(event, name, index) {
      if (name === "benefits") {
        this.formPayload.other.benefits[index].icon = event.target.files[0];
        this.showPhoto(event.target.files[0], name, index);
      } else {
        this.formPayload.other.procedures[index].icon = event.target.files[0];
        this.showPhoto(event.target.files[0], name, index);
      }
    },
    showPhoto(file, name, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === "benefits") {
          this.testingBenefit = e.target.result;
          this.formPayload.other.benefits[index].preview = e.target.result;
        } else {
          this.testingProcedure = e.target.result;
          this.formPayload.other.procedures[index].preview = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    saveItem() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append("type", this.formPayload.type);
      payload.append("other[about]", this.formPayload.other.about);
      payload.append("other[image]", this.formPayload.other.image);
      if (this.formPayload.other.redirect_url !== null) {
        payload.append(
          "other[redirect_url]",
          this.formPayload.other.redirect_url
        );
      }
      const appendData = (dataArray, type) => {
        if (dataArray !== null) {
          dataArray.forEach((data, index) => {
            Object.keys(data).forEach((key) => {
              if (
                key !== "preview" &&
                !(key === "icon" && data[key] === null)
              ) {
                payload.append(`other[${type}][${index}][${key}]`, data[key]);
              }
            });
          });
        }
      };
      appendData(this.formPayload.other.benefits, "benefits");
      appendData(this.formPayload.other.procedures, "procedures");
      this.$http
        .post(`/api/v1/admin/page/intern-sis`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Internsis Berhasil di update!");
          this.isLoading = false;
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style>
.product__icon {
  width: 24px;
  height: 24px;
}
</style>
